"use client";
import { BoundingBox, CaretDown, CheckCircle, House, Pencil, Plus, SignIn, UserPlus } from "@phosphor-icons/react";
import { motion } from "framer-motion";
import { Fragment, useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";
import NavLink from "./NavLink";

type Event = {
	action: "bits" | "followed" | "resubscribed" | "subscribed";
	actor: "alfww" | "xlxAciDxlx";
	createdAt: Date;
	details?: Record<string, unknown>;
	id: string;
};

const EVENT_TYPES: Event["action"][] = ["bits", "followed", "resubscribed", "subscribed"];
const EVENT_USERS: Event["actor"][] = ["alfww", "xlxAciDxlx"];

function getMockEvent(): Event {
	const action = EVENT_TYPES[Math.floor(Math.random() * EVENT_TYPES.length)];
	const actor = EVENT_USERS[Math.floor(Math.random() * EVENT_USERS.length)];

	return {
		action,
		actor,
		createdAt: new Date(),
		details: action === "bits" ? { value: Math.floor(Math.random() * (10000 - 5 + 1)) + 5 } : undefined,
		id: crypto.randomUUID(),
	};
}

export default function AppLayout() {
	const [activityLogDropdownOpen, setActivityLogDropdownOpen] = useState(false);
	const [events, setEvents] = useState<Event[]>([]);
	const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
	const [filters, setFilters] = useState<Event["action"][]>([]);
	useEffect(() => {
		if (filters.length === 0) {
			setFilteredEvents(events.slice(0, -displayedEventsCount));
			return;
		}

		setFilteredEvents(events.filter((event) => filters.includes(event.action)).slice(0, -displayedEventsCount));
	}, [events, filters]);

	const [displayedEventsCount, setDisplayedEventsCount] = useState(5);

	useEffect(() => {
		const interval = setInterval(() => {
			setEvents((currentEvents) => [...currentEvents, getMockEvent()]);
		}, 5000);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		if (events.length === 0 || events.length - 1 < displayedEventsCount) {
			return;
		}

		const difference = displayedEventsCount - events.length;
		if (difference === 0) {
			return;
		}

		setFilteredEvents((currentEvents) => currentEvents.slice(1));
	}, [displayedEventsCount, events]);

	const item = {
		hidden: { opacity: 0, scale: 1, transition: { duration: 0.3, staggerDirection: 1 } },
		show: { opacity: 1, scale: 1, transition: { duration: 0.3, staggerDirection: 1 } },
	};

	return (
		<Fragment>
			<div className="w-full flex-1 overflow-hidden rounded-t-lg border border-[#003366] border-b-0 bg-black shadow-[0_-10px_60px_rgba(0,51,102,0.9)]">
				<div className="flex h-full items-stretch">
					<aside className="flex h-full w-[200px] flex-col justify-between space-y-4 overflow-y-auto border-r border-r-gray-900 bg-gray-800/30 p-4 text-sm md:w-[250px]">
						<section className="space-y-4">
							<div className="flex items-center justify-center space-x-2">
								<h1 className="font-bold text-lg">TakeTenBot</h1>

								<div className="hidden rounded-lg bg-primary-700/40 px-2 py-[2px] font-bold text-[0.65em] text-primary-500 uppercase md:block">
									Alpha
								</div>
							</div>

							<nav className="space-y-6">
								<ul>
									<li>
										<NavLink active href="/" Icon={House}>
											Dashboard
										</NavLink>
									</li>
								</ul>

								<ul className="space-y-2">
									<li className="text-gray-400">Commands</li>
									<li>
										<NavLink disabled href="/" Icon={Plus}>
											Create New
										</NavLink>
									</li>
									<li>
										<NavLink href="/" Icon={Pencil}>
											View All
										</NavLink>
									</li>
								</ul>

								<ul className="space-y-2">
									<li className="text-gray-400">Connections</li>
									<li>
										<NavLink disabled href="/" Icon={Plus}>
											Create New
										</NavLink>
									</li>
									<li>
										<NavLink href="/" Icon={BoundingBox}>
											View All
										</NavLink>
									</li>
								</ul>
							</nav>
						</section>

						<nav className="border-t border-t-gray-800/50 pt-2">
							<ul className="space-y-2">
								<li>
									<NavLink disabled href="/" Icon={SignIn}>
										Sign In
									</NavLink>
								</li>
								<li>
									<NavLink disabled href="/" Icon={UserPlus}>
										Sign Up
									</NavLink>
								</li>
							</ul>
						</nav>
					</aside>

					<div className="h-full flex-1 space-y-6 overflow-y-auto p-6">
						<section>
							<h2 className="font-light text-2xl">Dashboard</h2>
							<h3 className="font-light text-gray-500 text-lg">Get an overview of your account</h3>
						</section>

						<div className="grid gap-4 md:grid-cols-2">
							<div className="space-y-2 rounded-lg border border-gray-800/40 bg-gray-700/25 px-4 py-2 text-center shadow-md">
								<h4 className="text-gray-600">Commands</h4>
								<h5 className="text-xl">0</h5>
							</div>

							<div className="space-y-2 rounded-lg border border-gray-800/40 bg-gray-700/25 px-4 py-2 text-center shadow-md">
								<h4 className="text-gray-600">Connections</h4>
								<h5 className="text-xl">0</h5>
							</div>
						</div>

						<section className="space-y-2">
							<div className="block items-center justify-between space-y-2 lg:flex lg:space-x-6 lg:space-y-0">
								<div className="flex items-center space-x-2">
									<h4 className="truncate font-light text-xl">Activity Log</h4>

									<div className="w-[80px] rounded-lg bg-gray-900/90 px-3 py-1 text-center text-xs shadow-inner">
										{filters.length > 0 ? `${filteredEvents.length}/${events.length}` : events.length}
									</div>
								</div>

								<div className="block space-x-0 space-y-2 lg:flex lg:items-center lg:space-x-4 lg:space-y-0">
									<div>
										<div
											className={twJoin(
												"border",
												"border-gray-700",
												"bg-gray-800",
												"min-w-[135px]",
												"relative",
												"text-xs",
												"transition-all",
												"duration-300",
												activityLogDropdownOpen ? "rounded-t-lg" : "rounded-lg",
											)}
										>
											<button
												className={twJoin(
													"-mb-[1px]",
													"border-b",
													"flex",
													"items-center",
													"justify-between",
													"relative",
													"space-x-2",
													"px-3",
													"py-1",
													"w-full",
													"z-20",
													activityLogDropdownOpen ? "border-b-gray-800" : "border-b-transparent",
												)}
												onClick={() => setActivityLogDropdownOpen(!activityLogDropdownOpen)}
												type="button"
											>
												<div className="flex items-center space-x-2">
													<div>Filters</div>

													<div className="rounded-lg bg-gray-900/50 px-2 py-1 text-xs shadow-inner">
														{filters.length}
													</div>
												</div>

												<CaretDown
													className={twJoin("duration-300", "transition-all", activityLogDropdownOpen && "rotate-180")}
													weight="bold"
												/>
											</button>

											<ul
												className={twJoin(
													"-left-[1px]",
													"-right-[1px]",
													"absolute",
													"bg-inherit",
													"border",
													"border-inherit",
													"duration-300",
													"p-1",
													"rounded-b-lg",
													"shadow-[0_2px_5px_rgba(0,0,0,0.85)]",
													"space-y-1",
													"transition-all",
													"whitespace-nowrap",
													"z-10",
													"*:divide-y",
													"disabled:*:*:opacity-50",
													"*:divide-gray-400",
													"*:*:w-full",
													"*:*:rounded-lg",
													"*:*:flex",
													"*:*:items-center",
													"*:*:px-1",
													"*:*:py-1",
													"*:*:space-x-2",
													"*:*:text-left",
													"*:*:transition-all",
													"*:*:duration-300",
													"[&:not([disabled])]:hover:*:*:bg-gray-700/50",
													activityLogDropdownOpen ? "opacity-100" : "pointer-events-none mt-2 opacity-0",
												)}
											>
												<li className="border-b border-b-gray-700/30 pb-1">
													<button
														className={twJoin("!pl-2 h-7", filters.length === 0 && "cursor-not-allowed")}
														disabled={filters.length === 0}
														onClick={(event) => {
															if (filters.length === 0) {
																event.preventDefault();
																return;
															}

															setFilters([]);
														}}
														type="button"
													>
														Disable All
													</button>
												</li>
												{EVENT_TYPES.map((type, index) => {
													return (
														<li key={type}>
															<button
																onClick={() => {
																	setFilters((currentFilters) => {
																		if (currentFilters.includes(type)) {
																			return currentFilters.filter((filter) => filter !== type);
																		}

																		return [...currentFilters, type];
																	});
																}}
																type="button"
															>
																<div className="w-[20px]">
																	{filters.includes(type) ? (
																		<CheckCircle className="text-[21px] text-green-700" weight="fill" />
																	) : (
																		<div className="p-[2px]">
																			<div className="size-[17px] rounded-full bg-gray-700" />
																		</div>
																	)}
																</div>

																<div>{type[0].toUpperCase() + type.slice(1)}</div>
															</button>
														</li>
													);
												})}
											</ul>
										</div>
									</div>

									<div className="flex items-center space-x-2 text-sm">
										<div className="truncate">Displayed Events:</div>

										<div className="flex items-center space-x-2">
											<input
												max={30}
												min={5}
												onChange={(event) => setDisplayedEventsCount(+event.currentTarget.value)}
												type="range"
												value={displayedEventsCount}
											/>

											<div className="inline-block text-gray-400">Max: 30</div>
										</div>
									</div>
								</div>
							</div>

							<motion.div animate="show" className="space-y-2" initial="hidden" layout>
								{filteredEvents.length === 0 ? (
									<motion.div className="px-4 py-2 text-center text-sm" variants={item}>
										No Events to display
									</motion.div>
								) : (
									filteredEvents.map((event) => {
										return (
											<motion.div
												className={twJoin(
													"bg-gray-800/50",
													"flex",
													"items-center",
													"justify-between",
													"origin-top",
													"rounded-lg",
													"px-4",
													"py-2",
												)}
												key={event.id}
												layoutId={`event-${event.id}`}
												variants={item}
											>
												<div className="text-gray-400">
													<div>
														<strong className="text-white">{event.actor}</strong>{" "}
														{event.action === "bits" ? (
															<Fragment>
																gave{" "}
																<strong className="text-gray-300">
																	{(event.details?.value as number).toLocaleString()}
																</strong>{" "}
																{event.action.slice(0, 1).toUpperCase() + event.action.slice(1)}
															</Fragment>
														) : (
															event.action
														)}
													</div>
												</div>

												<div className="text-right text-gray-500 text-xs">
													{new Date(event.createdAt).toLocaleString()}
												</div>
											</motion.div>
										);
									})
								)}
							</motion.div>
						</section>
					</div>
				</div>
			</div>

			{activityLogDropdownOpen ? (
				<button
					className="absolute top-0 left-0 h-full w-full"
					onClick={() => setActivityLogDropdownOpen(false)}
					type="button"
				/>
			) : null}
		</Fragment>
	);
}
