"use client";
import type { Icon } from "@phosphor-icons/react";
import Link from "next/link";
import type { PropsWithChildren } from "react";

type NavLinkProps = PropsWithChildren<{ href: string; Icon?: Icon } & ({ active?: true } | { disabled: boolean })>;

export default function NavLink({
	// @ts-expect-error
	active = false,
	children,
	// @ts-expect-error
	disabled = false,
	Icon,
	...props
}: NavLinkProps) {
	return (
		<Link
			{...props}
			className={[
				"block",
				"duration-300",
				"flex",
				"items-center",
				"rounded-lg",
				"px-4",
				"py-2",
				"space-x-2",
				"transition-all",
				!disabled && (active ? "bg-primary-800" : "hover:bg-primary-800/50"),
				disabled && "cursor-not-allowed opacity-50",
			]
				.filter(Boolean)
				.sort()
				.join(
					// biome-ignore lint/nursery/useSortedClasses: Biome incorrectly enables this rule for empty strings
					" ",
				)}
			onClick={(event) => event.preventDefault}
		>
			{Icon ? <Icon weight={active ? "fill" : "bold"} /> : null}
			<div>{children}</div>
		</Link>
	);
}
